export const Forms = [
  { name: 'Factum', isChecked: false, type: 'family' },
  { name: '10: Answer', isChecked: false, type: 'family' },
  { name: '20: Request for Information', isChecked: false, type: 'family' },
  {
    name: '30A: Request for Default Hearing',
    isChecked: false,
    type: 'family'
  },
  {
    name: '4: Notice of Change in Representation',
    isChecked: false,
    type: 'family'
  },
  { name: '10A: Reply By', isChecked: false, type: 'family' },
  { name: '22: Request to Admit', isChecked: false, type: 'family' },
  { name: '30B: Default Dispute', isChecked: false, type: 'family' },
  { name: '8.0.1: Automatic Order', isChecked: false, type: 'family' },
  { name: '12: Notice of Withdrawal', isChecked: false, type: 'family' },
  { name: '25: Order (general)', isChecked: false, type: 'family' },
  {
    name: '35.1: Affidavit (Decision & Parenting)',
    isChecked: false,
    type: 'family'
  },
  { name: '6A: Advertisement', isChecked: false, type: 'family' },
  {
    name: '13: Financial Statement',
    isChecked: false,
    type: 'family'
  },
  { name: '25A: Divorce Order', isChecked: false, type: 'family' },
  { name: '36: Affidavit for Divorce', isChecked: false, type: 'family' },
  {
    name: '6B: Affidavit of Service sworn/affirmed',
    isChecked: false,
    type: 'family'
  },
  { name: '13.1: Financial Statement', isChecked: false, type: 'family' },
  { name: '25B: Secure Treatment Order', isChecked: false, type: 'family' },
  {
    name: '36A: Certificate of Clerk (Divorce)',
    isChecked: false,
    type: 'family'
  },

  {
    name: '6C: Lawyer/Paralegals Cert. of Service',
    isChecked: false,
    type: 'family'
  },
  {
    name: '13A: Certificate of Financial Disclosure',
    isChecked: false,
    type: 'family'
  },
  { name: '25C: Adoption Order', isChecked: false, type: 'family' },
  { name: '36B: Certificate of Divorce', isChecked: false, type: 'family' },

  { name: '8: Application (General)', isChecked: false, type: 'family' },
  {
    name: '13B: Net Family Property Statement',
    isChecked: false,
    type: 'family'
  },
  { name: '25D: Order (Uncontested Trial)', isChecked: false, type: 'family' },
  { name: 'Offer to Settle', isChecked: false, type: 'family' },

  { name: '8A: Application (Divorce)', isChecked: false, type: 'family' },
  {
    name: '13C: Comparison of NFP Statements',
    isChecked: false,
    type: 'family'
  },
  {
    name: '25E: Notice Disputing Approval of Order',
    isChecked: false,
    type: 'family'
  },
  { name: 'Request for a Virtual Hearing', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: '14: Notice of Motion', isChecked: false, type: 'family' },
  { name: '25F: Restraining Order', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: '14A: Affidavit (General)', isChecked: false, type: 'family' },
  {
    name: '25G: Restr. Order on Motion Without Notice',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: '14B: Motion Form', isChecked: false, type: 'family' },
  {
    name: '25H: Order Terminating Rest. Order',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: '14C: Confirmation of Motion', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  {
    name: '14D: Order on Motion without Notice',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: '15: Motion to Change', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  {
    name: '15B:  Response to Motion to Change',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },

  { name: '17: Conference Notice', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },

  { name: 'null', type: 'family' },
  {
    name: '17A: Case Conference Brief (General)',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },

  { name: 'null', type: 'family' },
  { name: '17B: Case Conference Brief', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  {
    name: '17C: Settlement Conf. Brief.',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  {
    name: '17E: Trial Management Conference Brief',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: 'null', type: 'family' },
  { name: '17F: Confirmation of Conference', isChecked: false, type: 'family' },

  { name: 'Background Declaration', isChecked: false, type: 'immigration' },
  { name: 'Basis Of Claim', isChecked: false, type: 'immigration' },
  { name: 'Generic Declaration', isChecked: false, type: 'immigration' },
  { name: 'Refugee Claimants', isChecked: false, type: 'immigration' },
  { name: 'Use of Representative', isChecked: false, type: 'immigration' },
  {
    name: 'Affidavit Or Statutory Declaration',
    isChecked: false,
    type: 'immigration'
  },
  {
    name: 'Application to change the date or time of a proceeding',
    isChecked: false,
    type: 'immigration'
  },

  {
    name: 'Application to submit late disclosure to the Refugee Protection Division',
    isChecked: false,
    type: 'immigration'
  },
  {
    name: 'Application to extend the time limit to submit the Basis of Claim Form',
    isChecked: false,
    type: 'immigration'
  },
  {
    name: 'Request File Review Process',
    isChecked: false,
    type: 'immigration'
  },
];

export const ResponsiveForms = [
  { name: 'Factum', isChecked: false, type: 'family' },
  {
    name: '4: Notice of Change in Representation',
    isChecked: false,
    type: 'family'
  },
  { name: '8.0.1: Automatic Order', isChecked: false, type: 'family' },
  { name: '6A: Advertisement', isChecked: false, type: 'family' },
  {
    name: '6B: Affidavit of Service sworn/affirmed',
    isChecked: false,
    type: 'family'
  },
  {
    name: '6C: Lawyer/Paralegals Cert. of Service',
    isChecked: false,
    type: 'family'
  },
  { name: '8: Application (General)', isChecked: false, type: 'family' },
  { name: '8A: Application (Divorce)', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: '10: Answer', isChecked: false, type: 'family' },
  { name: '10A: Reply By', isChecked: false, type: 'family' },
  { name: '12: Notice of Withdrawal', isChecked: false, type: 'family' },
  {
    name: '13: Financial Statement',
    isChecked: false,
    type: 'family'
  },
  { name: '13.1: Financial Statement', isChecked: false, type: 'family' },
  {
    name: '13A: Certificate of Financial Disclosure',
    isChecked: false,
    type: 'family'
  },
  {
    name: '13B: Net Family Property Statement',
    isChecked: false,
    type: 'family'
  },
  {
    name: '13C: Comparison of NFP Statements',
    isChecked: false,
    type: 'family'
  },
  { name: '14: Notice of Motion', isChecked: false, type: 'family' },
  { name: '14A: Affidavit (General)', isChecked: false, type: 'family' },
  { name: '14B: Motion Form', isChecked: false, type: 'family' },
  { name: '14C: Confirmation of Motion', isChecked: false, type: 'family' },
  {
    name: '14D: Order on Motion without Notice',
    isChecked: false,
    type: 'family'
  },
  { name: '15: Motion to Change', isChecked: false, type: 'family' },
  {
    name: '15B:  Response to Motion to Change',
    isChecked: false,
    type: 'family'
  },
  {
    name: '17: Conference Notice',
    isChecked: false,
    type: 'family'
  },
  {
    name: '17A: Case Conference Brief (General)',
    isChecked: false,
    type: 'family'
  },
  { name: '17B: Case Conference Brief', isChecked: false, type: 'family' },
  {
    name: '17C: Settlement Conf. Brief.',
    isChecked: false,
    type: 'family'
  },
  {
    name: '17E: Trial Management Conference Brief',
    isChecked: false,
    type: 'family'
  },
  { name: '17F: Confirmation of Conference', isChecked: false, type: 'family' },
  { name: 'null', type: 'family' },
  { name: '20: Request for Information', isChecked: false, type: 'family' },
  { name: '22: Request to Admit', isChecked: false, type: 'family' },
  { name: '25: Order (general)', isChecked: false, type: 'family' },
  { name: '25A: Divorce Order', isChecked: false, type: 'family' },
  { name: '25B: Secure Treatment Order', isChecked: false, type: 'family' },
  { name: '25C: Adoption Order', isChecked: false, type: 'family' },
  { name: '25D: Order (Uncontested Trial)', isChecked: false, type: 'family' },
  {
    name: '25E: Notice Disputing Approval of Order',
    isChecked: false,
    type: 'family'
  },
  { name: '25F: Restraining Order', isChecked: false, type: 'family' },
  {
    name: '25G: Restr. Order on Motion Without Notice',
    isChecked: false,
    type: 'family'
  },
  {
    name: '25H: Order Terminating Rest. Order',
    isChecked: false,
    type: 'family'
  },
  { name: 'null', type: 'family' },
  {
    name: '30A: Request for Default Hearing',
    isChecked: false,
    type: 'family'
  },
  { name: '30B: Default Dispute', isChecked: false, type: 'family' },
  {
    name: '35.1: Affidavit (Decision & Parenting)',
    isChecked: false,
    type: 'family'
  },
  { name: '36: Affidavit for Divorce', isChecked: false, type: 'family' },
  {
    name: '36A: Certificate of Clerk (Divorce)',
    isChecked: false,
    type: 'family'
  },
  { name: '36B: Certificate of Divorce', isChecked: false, type: 'family' },
  { name: 'Offer to Settle', isChecked: false, type: 'family' },
  { name: 'Request for a Virtual Hearing', isChecked: false, type: 'family' }
];

export const DownloadableForms = [
  {
    name: `Affidavit (General).pdf`,
    url: 'form-affidavit-form',
    formName: '14A: Affidavit (general) dated'
  },
  {
    name: `Answer.pdf`,
    url: 'form-answer-form',
    formName: '10: Answer'
  },
  {
    name: `Application (Divorce).pdf`,
    url: 'form-application-divorce-form',
    formName: '8A: Application (Divorce)'
  },
  {
    name: `Automatic Order.pdf`,
    url: 'form-automatic-order',
    formName: '8.0.1: Automatic Order'
  },
  {
    name: `Case Conference Brief.pdf`,
    url: 'form-case-conference-brief',
    formName: '17B: Case Conference Brief'
  },
  {
    name: 'Affidavit for Divorce.pdf',
    url: 'form-affidavit-for-divorce-form',
    formName: '36: Affidavit for Divorce'
  },
  {
    name: 'Certificate of Clerk (Divorce).pdf',
    url: 'form-certificate-of-clerk-divorce-form',
    formName: '36A: Certificate of Clerk (Divorce)'
  },
  {
    name: `Certificate of Divorce.pdf`,
    url: 'form-certificate-divorce-form',
    formName: '36B: Certificate of Divorce'
  },
  {
    name: `Divorce Order.pdf`,
    url: 'form-divorce-order-form',
    formName: '25A: Divorce Order'
  },
  {
    name: `Factum.pdf`,
    url: 'form-factum',
    formName: 'Factum'
  },
  {
    name: `Net Family Property Statement.pdf`,
    url: 'form-family-property-statement',
    formName: '13B: Net Family Property Statement'
  },
  {
    name: `Financial Statement.pdf`,
    url: 'form-form13.1',
    formName: '13.1: Financial Statement'
  },
  {
    name: `Notice of Change in Representation.pdf`,
    url: 'form-notice-of-change',
    formName: '4: Notice of Change in Representation'
  },
  {
    name: `Advertisement.pdf`,
    url: 'form-advertisement',
    formName: '6A: Advertisement'
  },
  {
    name: `Affidavit of Service sworn/affirmed.pdf`,
    url: 'form-form6-b',
    formName: '6B: Affidavit of Service sworn/affirmed'
  },
  {
    name: `Lawyer or Paralegal Certificate of Service.pdf`,
    url: 'form-form6-c',
    formName: '6C: Lawyer or Paralegal Certificate of Service'
  },
  {
    name: `Application (General).pdf`,
    url: 'form-form8-application',
    formName: '8: Application (General)'
  },
  {
    name: `Financial Statement (Support Claims) sworn/affirmed.pdf`,
    url: 'form-support-claims',
    formName: '13: Financial Statement (Support Claims) sworn/affirmed'
  },
  {
    name: `Certificate of Financial Disclosure.pdf`,
    url: 'form-financial-disclosure',
    formName: '13A: Certificate of Financial Disclosure'
  },
  {
    name: `Comparison Net Family Statements.pdf`,
    url: 'form-comparison',
    formName: '13C: Comparison of Net Family Property Statements'
  },
  {
    name: `Notice of Motion.pdf`,
    url: 'form-notice-of-motion',
    formName: '14: Notice of Motion'
  },
  {
    name: `Confirmation of Motion.pdf`,
    url: 'form-confirmation-of-motion',
    formName: '14C: Confirmation of Motion'
  },
  {
    name: `Conference Notice.pdf`,
    url: 'form-conference-notice',
    formName: '17: Conference Notice'
  },
  {
    name: `Case Conference Brief (General).pdf`,
    url: 'form-case-conference-brief-general',
    formName: '17A: Case Conference Brief (General)'
  },
  {
    name: `Settlement Conference Brief - General.pdf`,
    url: 'form-settlement-conference',
    formName: '17C: Settlement Conference Brief - General'
  },
  {
    name: `Trial Management Conference Brief.pdf`,
    url: 'form-trial-management',
    formName: '17E: Trial Management Conference Brief'
  },
  {
    name: `Confirmation of Conference.pdf`,
    url: 'form-confirmation-of-conference',
    formName: '17F: Confirmation of Conference'
  },
  {
    name: `Request for Information.pdf`,
    url: 'form-request-for-information',
    formName: '20: Request for Information'
  },
  {
    name: `Request to Admit.pdf`,
    url: 'form-request-to-admit',
    formName: '22: Request to Admit'
  },
  {
    name: `Order (general).pdf`,
    url: 'form-order-general',
    formName: '25: Order (general)'
  },
  {
    name: `Secure Treatment Order.pdf`,
    url: 'form-secure-treatment',
    formName: '25B: Secure Treatment Order'
  },
  {
    name: `Adoption Order.pdf`,
    url: 'form-adoption-order',
    formName: '25C: Adoption Order'
  },
  {
    name: `Order (Uncontested Trial).pdf`,
    url: 'form-order-uncontested',
    formName: '25D: Order (Uncontested Trial)'
  },
  {
    name: `Notice Disputing Approval of Order.pdf`,
    url: 'form-disputing-approval',
    formName: '25E: Notice Disputing Approval of Order'
  },
  {
    name: `Restraining Order.pdf`,
    url: 'form-restraining-order',
    formName: '25F: Restraining Order'
  },
  {
    name: `Restraining Order on Motion without Notice.pdf`,
    url: 'form-restraining-order-motion',
    formName: '25G: Restraining Order on Motion without Notice'
  },
  {
    name: `Order Terminating Restraining Order.pdf`,
    url: 'form-terminating-order-motion',
    formName: '25H: Order Terminating Restraining Order'
  },
  {
    name: `Request for Default Hearing.pdf`,
    url: 'form-form30-a',
    formName: '30A: Request for Default Hearing'
  },
  {
    name: `Default Dispute.pdf`,
    url: 'form-form30-b',
    formName: '30B: Default Dispute'
  },
  {
    name: `Form 35 Affidavit.pdf`,
    url: 'form-form35.1',
    formName:
      '35.1: Affidavit (decision-making responsibility, parenting time, contact)'
  },
  {
    name: `Reply By.pdf`,
    url: 'form-instruction-financial',
    formName: '10A: Reply By'
  },
  {
    name: `Motion Form.pdf`,
    url: 'form-motion-form',
    formName: '14B: Motion Form'
  },
  {
    name: `Motion to Change.pdf`,
    url: 'form-motion-to-change',
    formName: '15: Motion to Change'
  },
  {
    name: `Offer to Settle.pdf`,
    url: 'form-offer-to-settle',
    formName: 'Offer to Settle'
  },
  {
    name: `Order on Motion without Notice.pdf`,
    url: 'form-order-on-motion',
    formName: '14D: Order on Motion without Notice'
  },
  {
    name: `Request for a Virtual Hearing.pdf`,
    url: 'form-request-virtual-hearing',
    formName: 'Request for a Virtual Hearing'
  },
  {
    name: `Response to Motion to Change.pdf`,
    url: 'form-response-motion-to-change',
    formName: '15B:  Response to Motion to Change'
  },
  {
    name: `Notice of Withdrawal.pdf`,
    url: 'form-withdrawal-instruction-financial',
    formName: '12: Notice of Withdrawal'
  },
  {
    name: 'Background Declaration.pdf',
    url: 'background-declaration',
    formName: 'Background Declaration'
  },
  {
    name: 'Basis Of Claim Form.pdf',
    url: 'basis-of-claim',
    formName: 'Basis Of Claim'
  },
  {
    name: 'Generic Declaration.pdf',
    url: 'generic-declaration',
    formName: 'Generic Declaration'
  },
  {
    name: 'Refugee Claimants.pdf',
    url: 'refugee-claimants',
    formName: 'Refugee Claimants'
  },
  {
    name: 'Use of Representative.pdf',
    url: 'use-of-representative',
    formName: 'Use of Representative',
    hasSignature: true
  },
  {
    name: 'Affidavit Or Statutory Declaration.pdf',
    url: 'affidavit-statutory-declaration',
    formName: 'Affidavit Or Statutory Declaration'
  },
  {
    name: 'Application to change the date or time of a proceeding.pdf',
    url: 'application-to-change-the-date-or-time-of-a-proceeding',
    formName: 'Application to change the date or time of a proceeding'
  },
  {
    name: 'Application to submit late disclosure to the Refugee Protection Division.pdf',
    url: 'application-to-submit-late-disclosure',
    formName: 'Application to submit late disclosure'
  },
  {
    name: 'Application to extend the time limit to submit the Basis of Claim Form.pdf',
    url: 'application-to-extend-the-time-limit-to-submit-the-basis-of-claim',
    formName: 'Application to extend the time limit to submit the Basis of Claim Form'
  },
  {
    name: 'Request File Review Process.pdf',
    url: 'request-file-review-process',
    formName: 'Request File Review Process'
  },
];
