import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { CalendarOptions } from '@fullcalendar/angular';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar';
import { STRIPE_SUBSCRIPTION } from 'app/enum/stripe.enum';
import { subscriptionDetails } from 'app/interfaces/stripe.interface';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clientNavItems } from '../../_clientNav';
import { matterNavItems } from '../../_matterNavs';
import { navClientItems, navItems } from '../../_nav';
import { userNavItems } from '../../_userNav';
import { Endpoints } from '../../config';
import * as MESSAGE from '../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../enum/local-storage.enum';
import { ROUTES_TO } from '../../enum/routes.enum';
import * as MENU from '../../enum/sidebar-menu';
import {
  ApiService,
  AuthService,
  BriefDocketService,
  CommonService,
  DataService,
  OneSignalService
} from '../../services';
import { SideBar } from '../../shared/config/side-menu';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultLayoutComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('eventModal') public eventModal: ModalDirective;
  @ViewChild('triggerMe') modalBtn: ElementRef;
  @ViewChild('calendar', { static: true, read: ElementRef })
  calender: ElementRef;

  endpoint = Endpoints;
  public sidebarMinimized = false;
  public sidebarMinimizedDevice = true;
  public navItems = navItems;
  public clientNavItems = clientNavItems;
  public matterNavItems = matterNavItems;
  public sideBarNavItems;
  public userNavItems = userNavItems;
  public getScreenHeight;
  public getScreenWidth;
  public showSideMenu = true;
  public fixedBoxOffsetTop;
  public fixedBoxOffsetTopOtherMethod;

  loggedUserDetail: any;
  userDetail: any = {};
  eventList = [];
  matterUserDetails: any;
  selectMatterType: FormGroup;
  userInfo: {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    accessLevel: number;
  };
  clientUserDetails;
  view: CalendarView = CalendarView.Month;
  dayView: CalendarView = CalendarView.Day;
  events: CalendarEvent[] = [];
  calendarOptions: CalendarOptions;
  viewDate: Date = new Date();
  eventTitle: string;
  locale = 'en';
  eventDate;
  notifications = false;
  taskList = false;
  showUserInfo = false;
  matterDetailsActive: boolean;
  userDetailsActive: boolean;
  isUserLoggedIn = false;
  showSideCalendarMatter = true;
  isShowAddMatter = true;
  isShowMiniCalendar = false;
  eventData: CalendarEvent[] = [];
  showTaskModal = false;
  userDetails;
  matterDetails;
  taskInfo: any = {};
  clerkList: any = [];
  taskData: any;
  matterId: string;
  showTaskDetails = false;
  taskDetails: any;
  title: string;
  confirm: string;
  cancel: string;
  confirmationMessage: string;
  showModal: boolean;
  message: string;
  status: string;
  currentDate = moment().format('dddd DD MMMM, YYYY');
  notificationsData: any = [];
  currentUser: any;
  notificationBadgeLength: string;
  type = '';
  alertMessage = '';
  format: boolean;
  disableSettings: boolean;
  destroy$ = new Subject<boolean>();
  matterMessageBadgeLength: string;
  messageBadgeLengthNumber: number;
  matterDocumentBadgeLength: string;
  documentBadgeLengthNumber: number;
  viewDocuments: any = [];
  viewedMessages: any = [];
  notificationMatterData = [];
  isIosDevice = Capacitor.getPlatform() === 'ios';
  collapseNav = true;
  showSubscriptionExpiredMessage = false;
  gracePeriod: string;
  subscriptionDetails: subscriptionDetails;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    public commonService: CommonService,
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private briefService: BriefDocketService,
    private oneSignalService: OneSignalService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('userDetails'));
  }

  async ngOnInit() {
    await this.initializeSubscriptionDetails();

    this.checkQueryParams();
    if (this.authService.checkLogin()) {
      this.isUserLoggedIn = true;
      await this.getUserDetails();
    }
    this.commonService.subscriptionExpiryChanged.subscribe(async status => {
      if (status) {
        await this.initializeSubscriptionDetails();
      }
    });

    this.oneSignalService.viewDocuments
      .pipe(takeUntil(this.destroy$))
      .subscribe(async res => {
        if (res) {
          const documentBadgeElement = document.querySelector(
            '.custom-badge-class-document'
          );
          this.documentBadgeLengthNumber = +this.matterDocumentBadgeLength;
          if (documentBadgeElement) {
            documentBadgeElement.textContent = '';
          }
          await this.readDocuments();
        }
      });

    this.oneSignalService.viewMessage
      .pipe(takeUntil(this.destroy$))
      .subscribe(async res => {
        if (res) {
          const messageBadgeElement = document.querySelector(
            '.custom-badge-class-message'
          );
          this.messageBadgeLengthNumber = +this.matterMessageBadgeLength;
          if (messageBadgeElement) {
            messageBadgeElement.textContent = '';
          }
          await this.readMessages();
        }
      });

    this.getMatterNotifications();
    this.disableSetting();
    await this.setMatterDetails();
    this.commonService.updateSideMenu
      .pipe(takeUntil(this.destroy$))
      .subscribe(async response => {
        if (response) {
          await this.getNotificationsLength(this.matterId);
          await this.checkMatterDetailActive(true);
        }
      });

    this.getClerks();
    this.dataService
      .getLawyersDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const taskLawyers = data;
        this.taskInfo.taskLawyers =
          taskLawyers !== undefined ? taskLawyers : [];
      });

    const accessLevel = +this.authService.getUserAccessLevel();
    this.sideBarNavItems = accessLevel < 2 ? clientNavItems : matterNavItems;

    this.commonService.showCalendar
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.showSideCalendarMatter = res;
        this.isShowMiniCalendar = true;
      });

    this.dataService
      .getAddMatterButtonActive()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isShowAddMatter = data;
      });

    this.selectMatterType = this.fb.group({
      matterTypeValue: ['']
    });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    this.dataService
      .getMatterDetailActive()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.matterDetailsActive = data;

        if (data) {
          setTimeout(() => {
            this.checkMatterDetailActive(data);
          }, 100);
        }
      });

    this.dataService
      .getUserDetailActive()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.userDetailsActive = data;
      });

    this.setCalendarOptions();

    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.sidebarMinimized = true;
    }
    this.hideSideMenu();

    this.commonService.updateNotificationBadge
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.getNotificationsLength('');
        }
      });

    this.getNotificationsLength('');
  }
  async initializeSubscriptionDetails() {
    this.subscriptionDetails = await this.authService.getSubscriptionExpiry();
    this.checkSubscriptionExpired(this.subscriptionDetails);
  }
  async checkSubscriptionExpired(expiryDetails) {
    const current = moment().format(STRIPE_SUBSCRIPTION.EXPIRY_DATE);
    const expiry = expiryDetails;

    if (
      !expiry?.subscriptionExpires ||
      expiry?.ignoreSubscription ||
      !expiry?.gracePeriodDate
    ) {
      return;
    }

    const expiryDate = moment(expiry.subscriptionExpires).format(
      STRIPE_SUBSCRIPTION.EXPIRY_DATE
    );

    this.gracePeriod = moment(expiryDetails.gracePeriodDate).format(
      STRIPE_SUBSCRIPTION.EXPIRY_DATE
    );

    this.showSubscriptionExpiredMessage = current >= expiryDate;
  }

  async getMatterNotifications() {
    this.notificationMatterData = await this.oneSignalService.getNotifications(
      '',
      this.currentUser
    );
  }

  ngAfterViewChecked(): void {
    const elements = document.getElementsByTagName('a');
    for (let i = 0; i < elements.length; i++) {
      elements[i].draggable = false;
    }

    const images = document.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
      if (images[i].className !== 'allow-drag') {
        images[i].draggable = false;
      }
    }

    this.navItems.map(nav => {
      if (nav.name === 'Notifications' && +nav.badge.text === 0) {
        nav.badge.text = '';
      }
    });

    this.clientNavItems.map(nav => {
      if (
        (nav.name === 'Messages' || nav.name === 'Documents') &&
        +nav.badge.text === 0
      ) {
        nav.badge.text = '';
      }
    });

    this.clientNavItems.map(nav => {
      if (nav.name === 'Documents' && +nav.badge.text === 0) {
        nav.badge.text = '';
      }
    });

    // if (this.collapseNav) {
    //  const data = document.getElementsByTagName('app-sidebar-nav-dropdown');
    //  if (data[0]) {
    //    if (data[0].classList.contains('open')) {
    //      data[0].classList.remove('open');
    //      this.collapseNav = false;
    //    }
    //  }
    // }
  }

  checkQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.action) {
        switch (params.action) {
          case 'deactivate':
            this.clientStatus();
            break;
          case 'password':
            this.resendCredentials();
            break;
          case 'archive':
            if (this.matterDetails?.status === 'open') {
              this.archiveMatter(this.matterDetails?._id, 'closed');
            } else {
              this.archiveMatter(this.matterDetails?._id, 'open');
            }
            break;
          default:
            break;
        }
      }
    });
  }

  private scrollToCenter(): void {
    let scrollbar;
    if (
      this.calender?.nativeElement?.getElementsByClassName('cal-time') !==
        undefined &&
      this.calender?.nativeElement?.getElementsByClassName('cal-time').length >
        0
    ) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        scrollbar =
          this.calender?.nativeElement?.getElementsByClassName(
            'cal-hour-segment'
          )[32];
        scrollbar.id = 'scroll';
        scrollbar.scrollIntoView({ behavior: 'smooth', block: 'center' });
        scrollbar.scrollIntoView(true);
      } else {
        scrollbar =
          this.calender?.nativeElement?.getElementsByClassName(
            'cal-hour-segment'
          )[32];
        scrollbar.id = 'scroll';
        scrollbar.scrollIntoView({ behavior: 'smooth', block: 'center' });
        scrollbar.scrollIntoView(true);
      }
    }
  }

  archiveMatter(matterId: string, status: string) {
    this.title = 'Warning';
    this.confirm = 'Yes';
    this.cancel = 'No';
    this.type = 'archive';
    this.alertMessage = '';
    if (status === 'open') {
      this.message = 'Are you sure you want to re-open this matter?';
      this.status = status;
      this.showModal = true;
    } else {
      this.message = 'Are you sure you want to archive this matter?';
      this.status = status;
      this.showModal = true;
    }
    this.router.navigate([this.getPathFromUrl(this.router.url)]);
  }

  clientStatus() {
    this.title = 'Warning';
    this.confirm = 'Yes';
    this.cancel = 'No';
    this.type = 'disable';
    if (this.clientUserDetails?.isActive) {
      this.message = 'Are you sure you want to disable this client?';
      this.alertMessage = `Note: This action would entail the removal of their login credentials and a complete disassociation from your law firm's records.`;
      this.format = true;
      this.showModal = true;
    } else {
      this.title = 'Enable User';
      this.message = 'Do you want to reinstate this user?';
      this.format = true;
      this.showModal = true;
    }
    this.router.navigate([this.getPathFromUrl(this.router.url)]);
  }

  updateMatterStatus(ev: any) {
    this.showModal = !this.showModal;
    if (ev.state) {
      if (ev.type === 'archive') {
        this.api
          .put(`${this.endpoint.matterStatus}/${this.matterId}`, {
            status: this.status
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            async (res: any) => {
              this.toastr.success(
                this.status === 'open'
                  ? MESSAGE.TOASTR.MESSAGE_OPENED
                  : MESSAGE.TOASTR.MESSAGE_ARCHIVED,
                MESSAGE.INFO_MESSAGES.SUCCESS
              );
              await this.getMatterDetails(true);
            },
            err => {}
          );
      } else if (ev.type === 'disable') {
        this.api
          .put(`${this.endpoint.disableUser}/${this.clientUserDetails?._id}`, {
            updateAccessLevel: false,
            actionType: !this.clientUserDetails?.isActive
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res: any) => {
              if (res) {
                this.toastr.success(
                  `User ${!this.clientUserDetails.isActive ? 'reinstated' : 'disabled'} successfully`,
                  MESSAGE.INFO_MESSAGES.SUCCESS
                );
                this.getClientUserDetails(true);
              }
            },
            error => {
              this.toastr.error(
                'Something went wrong',
                MESSAGE.INFO_MESSAGES.ALERT_TITLE
              );
            }
          );
      } else if (ev.type === 'resendPassword') {
        this.api
          .put(Endpoints.resetMemberPassword, { user: this.clientUserDetails })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (data: any) => {
              this.toastr.success(data?.message, MESSAGE.INFO_MESSAGES.SUCCESS);
            },
            error => {
              const errMessage = error.error || MESSAGE.RESPONSE.ERROR;

              this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
            }
          );
      } else if (ev.type === 'resendInvitation') {
        this.api
          .post(Endpoints.authenticatedUser, { users: this.clientUserDetails })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            data => {
              if (data) {
                this.toastr.success(
                  'Invitation sent successfully',
                  MESSAGE.INFO_MESSAGES.SUCCESS
                );
              }
            },
            error => {
              const errMessage = error.error || MESSAGE.RESPONSE.ERROR;

              this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
            }
          );
      }
    }
  }

  async getMatterDetails(updateSideNav = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${this.endpoint.getMatter}/${this.matterId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => {
            if (res) {
              this.matterDetails = res;
              localStorage.setItem(
                LOCAL_STORAGE.MATTER_NAV_DETAILS,
                JSON.stringify({
                  matterDetails: this.matterDetails,
                  userDetail: this.matterDetails.user
                })
              );

              if (this.matterNavItems) {
                const nav = JSON.parse(JSON.stringify(this.matterNavItems));

                if (updateSideNav) {
                  nav.forEach(nav => {
                    this.updateSidebarNavUrl(this.matterDetails, nav);
                  });
                }
                this.matterNavItems = nav;
              }
            }

            resolve();
          },
          error => {
            reject();
          }
        );
    });
  }

  /**
   *
   * @param param
   * @description Method is used to show the modal on the day clicked.
   */
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.eventModal.show();
    this.viewDate = date;
    const taskDate = moment(date).format('YYYY-MM-DD');
    this.getCalendarData(taskDate);
  }

  getCalendarData(taskDate) {
    const task = moment(taskDate).format('YYYY-MM-DD');

    try {
      this.api
        .getParams(`${this.endpoint.getTaskByDate}`, { fromDate: task })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (taskData: any) => {
            if (taskData) {
              setTimeout(() => {
                this.taskData = taskData;
                this.setEvent(taskData);
              }, 500);
            }
          },
          err => {}
        );
    } catch (err) {}
    this.scrollToCenter();
  }

  async getNotificationsLength(matterId: string) {
    if (matterId) {
      this.notificationsData = await this.oneSignalService.getNotifications(
        this.matterId || '',
        this.currentUser
      );
    } else {
      this.notificationsData = await this.oneSignalService.getNotifications(
        '',
        this.currentUser
      );

      if (this.notificationsData?.length > 0) {
        const viewedNotifications = await this.notificationsData.filter(
          notification => notification.viewed === false
        );
        this.notificationBadgeLength = viewedNotifications.length;
      } else {
        this.notificationBadgeLength = '0';
      }

      await this.updateNav();
    }
  }

  async getMessagesLength(matterId: string, updateNav = true) {
    if (this.notificationMatterData?.length > 0) {
      const messages = this.notificationMatterData.filter(
        data =>
          data.notificationType === 'message' && data.matterId === matterId
      );
      this.viewedMessages = messages.filter(data => data.viewed === false);
      this.matterMessageBadgeLength = this.viewedMessages.length;
    }

    if (updateNav) {
      await this.updateNav();
    }
  }

  async getDocumentsLength(matterId: string, updateNav = true) {
    const documents = this.notificationMatterData.filter(
      data => data.notificationType === 'document' && data.matterId === matterId
    );
    this.viewDocuments = documents.filter(data => data.viewed === false);
    this.matterDocumentBadgeLength = this.viewDocuments.length;
    if (updateNav) {
      await this.updateNav();
    }
  }

  readDocuments() {
    return new Promise((resolve, reject) => {
      try {
        const documentRead = {
          viewed: true
        };

        if (this.viewDocuments.length > 0) {
          for (let i = 0; i < this.viewDocuments.length; i++) {
            this.api
              .put(
                `${this.endpoint.readNotification}/${this.viewDocuments[i]._id}`,
                documentRead
              )
              .pipe(takeUntil(this.destroy$))
              .toPromise();
          }
        }
        resolve(true);
      } catch {
        reject(false);
      }
    });
  }

  readMessages() {
    return new Promise((resolve, reject) => {
      try {
        const messageRead = {
          viewed: true
        };
        if (this.viewedMessages.length > 0) {
          for (let i = 0; i < this.viewedMessages.length; i++) {
            this.api
              .put(
                `${this.endpoint.readNotification}/${this.viewedMessages[i]._id}`,
                messageRead
              )
              .pipe(takeUntil(this.destroy$))
              .toPromise();
          }
        }
        resolve(true);
      } catch {
        reject(false);
      }
    });
  }

  async setMatterDetails() {
    if (!this.matterId) {
      this.matterId = JSON.parse(localStorage.getItem('matterDetails'))?._id;
      await this.getDocumentsLength(this.matterId);
      await this.getMessagesLength(this.matterId);
    }
    const index = 0;
    this.dataService
      .getMatterDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async data => {
        if (data?._id !== '') {
          this.matterDetails = data;
          this.matterId = data?._id;
          await this.getDocumentsLength(this.matterId);
          await this.getMessagesLength(this.matterId);
          this.getClientUserDetails();
          await this.checkMatterDetailActive(true);
        } else {
          const queryParams = this.activatedRoute.snapshot.queryParamMap;
        }
      });

    if (!this.matterDetails && this.matterId) {
      await this.getMatterDetails();
    }

    if (!this.clientUserDetails) {
      this.getClientUserDetails();
    }
    await this.isMatterIdPresent();
  }

  async isMatterIdPresent(): Promise<void> {
    if (!this.matterId) {
      this.getNotificationsLength('');
    }
  }

  getClerks() {
    if (this.currentUser?.accessLevel && this.currentUser?.accessLevel !== 1) {
      this.api
        .get(`${Endpoints.getClerks}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          if (data) {
            this.clerkList = data;
          }
        });
    }
  }

  eventTimesChanged(
    { event, newStart, newEnd }: CalendarEventTimesChangedEvent,
    view: string
  ): void {
    if (event.meta.type !== 'docket') {
      const selectedTask = this.taskData.filter(
        task => task._id === event.meta.taskId
      )[0];
      const startTime = moment(newStart).format('HH:mm');
      const endTime = moment(newEnd).format('HH:mm');
      if (selectedTask !== undefined) {
        selectedTask.taskDate = newStart.toISOString().split('T')[0];
        selectedTask.taskId = event.meta.taskId;
        selectedTask.taskStart = startTime;
        selectedTask.taskEnd = endTime;

        this.api
          .put(this.endpoint.matterTask, selectedTask)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res: any) => {
              this.eventData = this.eventData.map(iEvent => {
                if (iEvent === event) {
                  return {
                    ...event,
                    start: newStart,
                    end: newEnd
                  };
                }
                return iEvent;
              });
              this.dataService.setTasks(true);
              this.toastr.success('Event saved');
            },
            err => {
              const errMessage = err.error || MESSAGE.RESPONSE.ERROR;

              this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
            }
          );
      }
    }
  }

  badgeLengthNumber: number;
  async updateNav(): Promise<void> {
    const badgeElement = document.querySelector('.custom-badge-class');
    this.badgeLengthNumber = +this.notificationBadgeLength;
    if (badgeElement) {
      badgeElement.textContent = String(
        this.badgeLengthNumber === 0 ? '' : this.notificationBadgeLength
      );
    }

    const documentBadgeElement = document.querySelector(
      '.custom-badge-class-document'
    );
    this.documentBadgeLengthNumber = +this.matterDocumentBadgeLength || 0;
    if (documentBadgeElement) {
      documentBadgeElement.textContent = String(
        this.documentBadgeLengthNumber === 0
          ? ''
          : this.matterDocumentBadgeLength
      );
    }

    const messageBadgeElement = document.querySelector(
      '.custom-badge-class-message'
    );
    this.messageBadgeLengthNumber = +this.matterMessageBadgeLength || 0;

    //Sending message count for Message Tab
    if (this.messageBadgeLengthNumber) {
      this.dataService.messageCount.next(this.messageBadgeLengthNumber);
    }

    if (messageBadgeElement) {
      messageBadgeElement.textContent = String(
        this.messageBadgeLengthNumber === 0 ? '' : this.matterMessageBadgeLength
      );
    }

    this.navItems = this.navItems.map(navItems => {
      if (navItems.name === 'Notifications') {
        navItems.badge.text = this.notificationBadgeLength || '';
      }
      return navItems;
    });

    this.clientNavItems = this.clientNavItems.map(clientNavItems => {
      if (clientNavItems.name === 'Messages') {
        clientNavItems.badge.text = this.matterMessageBadgeLength || '';
      }
      return clientNavItems;
    });

    this.clientNavItems = this.clientNavItems.map(clientNavItems => {
      if (clientNavItems.name === 'Documents') {
        clientNavItems.badge.text = this.matterDocumentBadgeLength || '';
      }
      return clientNavItems;
    });

    this.cdr.detectChanges();
  }

  /**
   *
   * @param data Flag to check if the matter detail is active or not
   * @description Method is used to update the side menu.
   */
  async checkMatterDetailActive(data: any) {
    this.notificationMatterData = await this.oneSignalService.getNotifications(
      '',
      this.currentUser
    );

    this.getMessagesLength(this.matterId, false);
    this.getDocumentsLength(this.matterId, false);

    this.matterDetailsActive = data;
    this.matterUserDetails =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE.MATTER_NAV_DETAILS)) ||
      this.dataService.getSelectedUserData.getValue();
    const user = this.matterUserDetails?.userDetail;
    const matter = this.matterUserDetails?.matterDetails;

    if (matter) {
      this.disableSettings = false;
    }

    this.badgeLengthNumber = +this.notificationBadgeLength;

    if (user !== null && matter !== null) {
      const sideMenuMatters = this.sideBarNavItems?.filter((nav: any) => {
        if (nav.name === 'Notifications') {
          nav.badge.text =
            this.badgeLengthNumber === 0 ? '' : this.notificationBadgeLength;
        }

        if (nav.name === 'Messages') {
          nav.badge.text =
            +this.matterMessageBadgeLength === 0
              ? ''
              : this.matterMessageBadgeLength;
        }

        if (nav.name === 'Documents') {
          nav.badge.text =
            this.documentBadgeLengthNumber === 0
              ? ''
              : this.matterDocumentBadgeLength;
        }

        this.updateSidebarNavUrl(matter, nav);
        nav = this.updateSideMenu(nav, user, matter);
        return nav;
      });

      this.matterNavItems = [];
      try {
        this.cdr.detectChanges();
      } catch (err) {
        console.log(
          '** ->  ~ file: default-layout.component.ts:456 ~ checkMatterDetailActive ~ err:',
          err
        );
      }
      this.matterNavItems = sideMenuMatters;
    }

    if (this.userInfo?.accessLevel === 2 || this.userInfo?.accessLevel === 3) {
      this.matterNavItems = this.matterNavItems.filter(
        data => data.name !== 'Accounts' && data.name !== 'Settings'
      );
    }
  }

  /**
   *
   * @param nav
   * @param user
   * @param matter
   * @returns nav item
   * @description Method is used to update the side menu dynamically.
   */
  updateSideMenu(nav: any, user: any, matter: any) {
    if (nav?.url?.includes(MENU.SIDEBAR_MENU_URLS.BIN)) {
      nav.class = MENU.SIDEBAR_MENU_CLASS.HIDEMENU;
    }

    if (
      this.sidebarMinimized &&
      nav.url === ROUTES_TO.CLIENTNAME &&
      this.matterDetailsActive
    ) {
      const fullName = `${user?.lastName.toUpperCase()}, ${user?.firstName}  ${user?.middleName || ''}`;
      nav.name =
        this.getScreenWidth > 992
          ? this.getUserInitials(user.firstName, user.lastName)
          : fullName.length >= 16
            ? `${fullName.slice(0, 15)}...`
            : fullName;
      nav.attributes = { disabled: true };
      nav.class =
        this.getScreenWidth > 992
          ? `${MENU.SIDEBAR_MENU_CLASS.SHOWONCOLLAPSE}`
          : `${MENU.SIDEBAR_MENU_CLASS.CLIENTNAME}  child-side-bar`;
      nav.icon = '';
    } else {
      if (nav.url === ROUTES_TO.CLIENTNAME && this.matterDetailsActive) {
        if (user) {
          const fullName = `${user?.lastName.toUpperCase()}, ${user?.firstName}  ${user?.middleName || ''}`;
          nav.name =
            fullName.length >= 16 ? `${fullName.slice(0, 15)}...` : fullName;
          nav.attributes = { disabled: true };
          nav.class = `${MENU.SIDEBAR_MENU_CLASS.CLIENTNAME}  child-side-bar`;
          nav.icon = '';
        } else {
          nav.name = MENU.SIDEBAR_MENU.MATTERS;
          nav.icon = 'icon-drawer';
          nav.class = 'hide';
          nav.attributes = { disabled: false };
        }
      } else if (nav.url === ROUTES_TO.CLIENTNAME) {
        nav.name = MENU.SIDEBAR_MENU.MATTERS;
        nav.icon = 'icon-drawer';
        nav.class = '';
        nav.attributes = { disabled: false };
      } else if (nav.url === ROUTES_TO.HOME) {
        this.updateSidebarNavUrl(matter, nav);
      } else if (
        !user &&
        nav.name !== ROUTES_TO.ACCOUNTS &&
        nav.name !== ROUTES_TO.CALENDAR &&
        nav.url !== ROUTES_TO.MATTERS
      ) {
        if (nav?.children) {
          nav.children.forEach(child => {
            child.attributes = { disabled: true };
          });
        } else {
          nav.attributes = { disabled: true };
        }
      } else {
        if (nav?.children) {
          nav.children.forEach(child => {
            child.attributes = { disabled: false };
          });
        } else {
          nav.attributes = { disabled: false };
        }
      }
    }
    return nav;
  }

  hideSideMenu() {
    const accessLevel = +this.authService.getUserAccessLevel();
    this.hideMainMenu(accessLevel < 2 ? navClientItems : navItems);
    this.hideMatterMenu(this.sideBarNavItems);
  }

  hideMainMenu(nav: any) {
    const accessLevel = +this.authService.getUserAccessLevel();
    if (accessLevel < 2) {
      this.navItems = nav.filter(data => {
        if (
          data.url?.includes('home') ||
          data.url?.includes('accounts') ||
          data.url?.includes('archives') ||
          data.url?.includes('calendar')
        ) {
          return;
        } else if (data.url?.divider === true) {
          return;
        }
        return data;
      });
    }
    if (accessLevel === 2 || accessLevel === 3) {
      this.navItems = nav.filter(data => data.name !== 'Accounts');
    }
  }

  hideMatterMenu(nav: any) {
    const accessLevel = +this.authService.getUserAccessLevel();
    if (accessLevel < 2) {
      this.matterNavItems = nav.filter(data => {
        if (data.url?.includes('home')) {
          return;
        } else if (data.url?.includes('calendar')) {
          return;
        } else if (data.url?.includes('matters/brief-dockets')) {
          data.name = 'My Info';
          return;
        } else if (data.url?.includes('matters/precedent')) {
          return;
        } else if (data?.url?.includes('matters/compliance')) {
          return;
        } else if (data.name === 'Data & Forms') {
          data.name = 'Data';
        } else {
          return data;
        }
      });
    }
  }

  /**
   * @description Get user detail
   */
  async getUserDetails() {
    this.commonService.userName
      .pipe(takeUntil(this.destroy$))
      .subscribe(username => {
        this.loggedUserDetail = username;
      });

    return new Promise((resolve, reject) => {
      this.api
        .get(this.endpoint.user)
        .pipe(takeUntil(this.destroy$))
        .subscribe((details: any) => {
          try {
            if (details) {
              this.loggedUserDetail = details;
              this.userInfo = {
                userId: '',
                userName: `${details.firstName} ${details.lastName}`,
                firstName: details.firstName,
                lastName: details.lastName,
                userEmail: details.email,
                accessLevel: details.accessLevel
              };
              resolve(true);
            }
          } catch (e) {
            reject(false);
          }
        });
    });
  }

  resendCredentials() {
    this.title = `${this.clientUserDetails?.isAuthenticated ? 'Resend password' : 'Resend invitation'}`;
    this.confirm = 'Yes';
    this.cancel = 'No';
    this.type = `${this.clientUserDetails?.isAuthenticated ? 'resendPassword' : 'resendInvitation'}`;
    this.message = `${
      this.clientUserDetails?.isAuthenticated
        ? 'Resend password to this user?'
        : 'Resend invitation to this user?'
    }`;

    this.alertMessage = '';
    this.showModal = true;
    this.router.navigate([this.getPathFromUrl(this.router.url)]);
  }

  /**
   * @description Method is used to set the calendar options.
   */
  setCalendarOptions() {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      events: [
        { title: 'event 1', date: '2021-11-12' },
        { title: 'event 2', date: '2021-11-17' }
      ],
      contentHeight: 600,
      themeSystem: 'standard',
      headerToolbar: {
        right: 'today prev,next',
        center: 'title',
        left: 'dayGridMonth,timeGridWeek,timeGridDay'
      }
    };
  }

  /**
   *
   * @param eventRecord Event records
   * @description Method is used to
   */
  setEvent(eventRecord: any) {
    const eventDate = moment(this.viewDate).format('YYYY-MM-DD');

    this.isShowMiniCalendar = true;
    this.eventData = [];
    eventRecord.forEach(task => {
      const startTime = task.taskStart.split(':');
      const endTime = task?.taskEnd.split(':');
      let date, endDate;
      if (
        task?.completed &&
        task.completed &&
        task?.taskCloseDate !== '' &&
        task.taskCloseDate === eventDate
      ) {
        date = moment(task.taskCloseDate)
          .add(startTime[0], 'hour')
          .add(startTime[1], 'minute');
        endDate = moment(task.taskCloseDate)
          .add(endTime[0], 'hour')
          .add(endTime[1], 'minute');
      } else if (task?.completed === false) {
        date = moment(task.taskDate)
          .add(startTime[0], 'hour')
          .add(startTime[1], 'minute');
        endDate = moment(task.taskDate)
          .add(endTime[0], 'hour')
          .add(endTime[1], 'minute');
      }
      if (date !== undefined) {
        const taskDate = moment(date, moment.defaultFormat).toDate();
        const taskEndDate = moment(endDate, moment.defaultFormat).toDate();
        const title = `${task.matterNo} - ${task.user?.lastName}, ${task.user?.firstName.slice(0, 1)}.`;
        let headerTitle;

        if (
          task.matterType &&
          task.matterType !== '' &&
          task?.matterType.toLowerCase() === 'immigration'
        ) {
          headerTitle = `${task?.matterType?.slice(0, 1) + 'MM'} - ${task.taskTitle}`;
        } else {
          headerTitle = `${task?.matterType?.slice(0, 1) + 'M'} - ${task.taskTitle}`;
        }
        if (task?.taskTitle !== 'File opened') {
          this.eventData.push({
            start: taskDate,
            title: `${title} ${headerTitle}`,
            id: task.matterId,
            meta: {
              type: task.completed ? 'docket' : 'task',
              taskId: task._id,
              taskDetails: task
            },
            color: {
              primary: task.completed ? '#858585' : '#1e90ff',
              secondary:
                task.completed === true
                  ? '#858585'
                  : !task.taskColor
                    ? '#1e90ff'
                    : task.taskColor
            },
            end: taskEndDate,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: true
          });
        }
      }
    });
  }

  /**
   *
   * @param taskData
   @description Method
   */
  getTasks(taskData?: any) {
    this.eventData = [];
    this.isShowMiniCalendar = false;
    if (taskData && taskData.length > 0) {
      this.taskData = taskData;
      this.setEvent(taskData);
      this.isShowMiniCalendar = true;
    } else {
      this.briefService
        .getMatterDetails(this.matterUserDetails?.matterDetails?._id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          if (result?.tasks.length > 0) {
            this.taskData = result.tasks;
            this.setEvent(result.tasks);
            this.isShowMiniCalendar = true;
          } else {
            this.isShowMiniCalendar = true;
          }
        });
    }
  }

  getDateString() {
    const date = new Date();
    return date.toDateString();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /**
   *
   * @param e
   * @description Called when the side menu is toggle.
   */
  toggleMinimize(e) {
    if (this.getScreenWidth < 992) {
      this.sidebarMinimizedDevice = e;
    } else if (this.getScreenWidth > 992 && this.getScreenHeight !== 1180) {
      this.sidebarMinimized = e;
    }
  }

  /**
   *
   * @param e
   * @description Called when the side menu is toggle and update the side menu.
   */

  toggleSideMenu(value) {
    if (this.getScreenWidth < 992) {
      this.sidebarMinimizedDevice = value;
    } else if (this.getScreenWidth > 992 && this.getScreenHeight !== 1180) {
      this.sidebarMinimized = value;
    }

    if (this.matterUserDetails?.matterDetails) {
      const nav = this.router.url.split(/[/ ]+/).pop();
      const isActive =
        SideBar.indexOf(nav) > -1
          ? nav.includes('notification') && this.matterDetailsActive
            ? true
            : !nav.includes('notification')
          : false;
      this.checkMatterDetailActive(isActive);
    }
  }

  toggleMenu(ev) {
    if (this.getScreenWidth < 992) {
      this.sidebarMinimizedDevice = ev;
    } else if (this.getScreenWidth > 992 && this.getScreenHeight !== 1180) {
      this.sidebarMinimized = ev;
    }
  }

  disableSetting() {
    this.disableSettings = false;
    this.commonService.disableSettings
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.disableSettings = data;
        }
      });
  }

  /**
   *
   * @param name
   * @returns Names initials
   */
  getUserInitials(firstName: string, lastName: string) {
    return (
      firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
    );
  }

  /**
   * @description Method is used to toggle the notification modal
   */
  toggleNotifications() {
    this.notifications = !this.notifications;
    this.taskList = false;
    this.showUserInfo = false;
  }

  /**
   * @description Method is used to toggle the task list.
   */
  toggleTaskList() {
    this.taskList = !this.taskList;
    this.notifications = false;
    this.showUserInfo = false;
  }

  /**
   * @description Method is used to toggle the user info modal.
   */
  toggleUserInfo() {
    this.showUserInfo = !this.showUserInfo;
    this.taskList = false;
    this.notifications = false;
  }

  /**
   * @description Method is used to clear the local storage and update the last login time at server.
   */
  logOut() {
    if (this.loggedUserDetail) {
      const formData = {
        email: this.loggedUserDetail.email
      };

      this.authService
        .logout(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          async res => {
            if (res) {
              this.dataService.getSelectedUserData.next(null);
              this.dataService.matterDetailActive.next(false);
              if (this.loggedUserDetail.accessLevel >= 3) {
                const playerId =
                  await this.oneSignalService.getPlayerIdFromLocalStorage();
                const userId =
                  await this.oneSignalService.getCurrentUserDetails();
                if (playerId && userId) {
                  await this.oneSignalService.deletePlayerId(playerId, userId);
                }
              }
              const userName = localStorage.getItem('userName');
              localStorage.clear();
              localStorage.setItem('userName', userName);

              this.toastr.success(
                MESSAGE.INFO_MESSAGES.LOGOUT,
                MESSAGE.INFO_MESSAGES.SUCCESS
              );
              this.router.navigate([ROUTES_TO.LOGIN]);
            }
          },
          err => {
            const errorMessage = err.error || MESSAGE.RESPONSE.ERROR;
            this.toastr.error(errorMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
          }
        );
    }
  }

  openMatterModal() {
    this.myModal.show();
  }

  selectValue(value) {
    this.myModal.hide();
    this.dataService.setMatterType(value);
    this.router.navigate([ROUTES_TO.ADD_MATTERS], {
      queryParams: { matterType: JSON.stringify(value) }
    });
  }

  /**
   *
   * @param ev
   * @description Method is used to show event modal
   */
  eventClicked(ev: any) {
    const matterId = ev.event.id;

    if (matterId !== this.matterId) {
      this.taskDetails = ev.event;

      this.showTaskDetails = true;
    } else {
      const matterType = ev.event.meta.type === 'docket' ? 3 : 2;
      this.eventModal.hide();
      setTimeout(() => {
        this.commonService.isEventClicked.next(matterType);
      }, 100);
      this.router.navigate([`/matters/${matterId}/brief-dockets`]);
    }
  }

  /**
   *
   * @param matter
   * @param nav
   * @description Method is used to update the sidebar nav urls
   */
  updateSidebarNavUrl(matter, nav) {
    const accessLevel = +this.authService.getUserAccessLevel();
    if (matter) {
      switch (nav.name) {
        case 'Memo(s)':
          nav.url = `/matters/${matter._id}/memo`;
          break;
        case 'Accounting':
          nav.url = `/matters/${matter._id}/accounting`;
          break;
        case 'Approvals':
          nav.url = `/matters/${matter._id}/approvals`;
          break;
        case 'Brief':
          nav.url = `/matters/${matter._id}/brief-dockets`;
          break;
        case 'Compliance':
          nav.url = `/matters/${matter._id}/compliance`;
          break;
        case 'Case Progress/Tasks':
          nav.url = `/matters/${matter._id}/case-progress`;
          break;
        case 'Data':
          nav.url = `/matters/${matter._id}/data`;
          break;
        case 'Dockets':
          nav.url = `/matters/${matter._id}/dockets`;
          break;
        case 'Documents':
          nav.url = `/matters/${matter._id}/documents`;
          break;
        case 'Forms':
          nav.url = `/matters/${matter._id}/forms`;
          break;
        case 'Messages':
          nav.url = `/matters/${matter._id}/chat`;
          break;
        case 'Precedents':
          nav.url = `/matters/${matter._id}/precedents`;
          break;
        case 'References':
          nav.url = `/matters/${matter._id}/references`;
          break;
        case 'Retainer':
          nav.url = `/matters/${matter._id}/retainer`;
          break;
        case 'My Info':
          nav.url = `/matters/${matter._id}/brief-dockets`;
          break;
        case 'Questionnaire':
          nav.url = `/matters/${matter._id}/questionnaire`;
          break;
        case 'Invoices':
          nav.url = `/matters/${matter._id}/invoices`;
          break;
        case 'Notifications':
          nav.url = `${accessLevel < 2 ? '/notifications' : `/matters/${matter._id}/notifications`}`;
          break;
        case 'Settings': {
          this.collapseNav = true;
          const url = location.href.split('#')[1];
          nav.children.forEach(child => {
            if (
              child.name === 'DEACTIVATE CLIENT' ||
              child.name === 'REINSTATE CLIENT'
            ) {
              child.name = this.clientUserDetails?.isActive
                ? 'DEACTIVATE CLIENT'
                : 'REINSTATE CLIENT';
              child.class = this.clientUserDetails?.isActive
                ? 'error'
                : 'success';
            } else if (
              child.name === 'RESEND PASSWORD' ||
              child.name === 'RESEND INVITATION'
            ) {
              child.name = this.clientUserDetails?.isAuthenticated
                ? 'RESEND PASSWORD'
                : 'RESEND INVITATION';
            } else if (
              child.name === 'MOVE TO ARCHIVE' ||
              child.name === 'RE-OPEN'
            ) {
              child.name =
                this.matterDetails?.status === 'open'
                  ? 'MOVE TO ARCHIVE'
                  : 'RE-OPEN';
              child.class =
                this.matterDetails?.status === 'open' ? 'error' : 'success';
            }
            child.url = url;
          });
          break;
        }
      }
    }
  }

  closeEvent() {
    this.eventModal.hide();
  }

  openTaskModal() {
    this.taskInfo.matterId = this.matterDetails?._id;
    this.taskInfo.clientId = this.matterDetails?.clientId;
    this.taskInfo.subscriberId = this.matterDetails?.subscriberId;
    this.showTaskModal = true;
  }

  onTaskAdded(ev: any) {
    this.getCalendarData(ev.eventDate);
    this.showTaskModal = false;
  }

  onTaskDetailsModalClosed(ev: any) {
    this.showTaskDetails = false;
    this.eventModal.hide();
  }

  getClientUserDetails(updateSideNav = false) {
    if (this.matterDetails?.user?.email) {
      this.api
        .getParams(this.endpoint.user, {
          email: encodeURIComponent(this.matterDetails?.user?.email)
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.clientUserDetails = res;

          const nav = JSON.parse(JSON.stringify(this.matterNavItems));

          if (updateSideNav) {
            nav.forEach(nav => {
              this.updateSidebarNavUrl(this.matterDetails, nav);
            });
          }
          this.matterNavItems = nav;
        });
    }
  }

  checkForLawyer() {
    if (this.userInfo?.accessLevel === 2 || this.userInfo?.accessLevel === 3) {
      this.navItems = this.navItems.map(navItems => {
        if (navItems.name === 'Matters') {
          navItems.url = '/matters/my-matters';
        }
        return navItems;
      });
    }
  }

  getPathFromUrl(url) {
    return url.split('?')[0];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
