import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { Endpoints } from '../config';
import { CommonService } from './common.service';

@Injectable({
 providedIn: 'root',
})
export class FormsService implements OnDestroy {
 userDetail;
 base_url = env.base_url;
 endpoints = Endpoints;
 public verifyToken = new BehaviorSubject<any>(null);
 isLawyerSign: Subject<boolean> = new Subject<boolean>();
 destroy$ = new Subject<boolean>();

 constructor(private commonService: CommonService, private toastr: ToastrService) {}

 setVerifyTokenData(data: any) {
  this.verifyToken.next(data);
 }

 getVerifyToken(): Observable<any> {
  return this.verifyToken.asObservable();
 }

 isUrlExpired(createdTs: number): boolean {
  const expiryTime = new Date(createdTs);
  expiryTime.setHours(24);
  const currentTime = new Date();
  return currentTime > expiryTime;
 }

 /**
  * @param formName
  * @param matterPartyType
  * @param personName
  * @description Function to get file name for generate pdf
  * @return {string}
  */
 getFileName(formName: string, matterPartyType: string, personName: string, isImmForm = false): string {
  const firstName = personName?.split('')[0];
  const lastName = personName?.split(' ')[1];

  const person = `${firstName?.toUpperCase() ?? ''}${firstName ? firstName.slice(1) : ''}${
   firstName && lastName ? '.' : ''
  } ${lastName?.charAt(0).toUpperCase() ?? ''}${lastName ? lastName.slice(1) : ''}`;

  const matterParty = matterPartyType?.charAt(0)?.toUpperCase() + matterPartyType?.slice(1);

  const todayDate = moment().format('DD-MMMM-YYYY');

  const fileName = isImmForm
   ? `${formName} - ${person} - ${todayDate}.pdf`
   : `${formName} - ${matterParty} - ${person} - ${todayDate}.pdf`;
  return fileName;
 }

 addPageNumbers(count?: number) {
  document.getElementById('pgNo').innerHTML = '';
  let top = 0;
  let totalPages = Math.ceil(document.body.scrollHeight / 940); //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
  for (let i = 1; i <= (count || totalPages); i++) {
   const pageNumberDiv = document.getElementById('pgNo');
   const div = document.createElement('div');
   div.innerText = 'Page ' + i;
   div.style.position = 'absolute';
   top = i === 1 ? 1350 : top + 1360 + 43;
   div.style.top = top.toString() + 'px';
   div.style.right = '40px';
   div.style.zIndex = '999';
   div.style.height = '16px';
   div.style.fontSize = '12px';
   pageNumberDiv.appendChild(div);
  }
 }

 printPDF(pageCount?: number) {
  setTimeout(() => {
   window.print();
  }, 1000);
 }

 async getSignature(formName, id) {
  return new Promise((resolve, reject) => {
   const params = { userId: id };
   if (id) {
    this.commonService
     .getFile(params, `${this.base_url}${Endpoints.getSignature}`)
     .pipe(takeUntil(this.destroy$))
     .subscribe(
      async (res: any) => {
       if (res) {
        formName.get('lawyerSignature').setValue((await this.blobToBase64(res)) || '');
        if (formName.controls.lawyerSignatureSecond !== undefined) {
         formName.get('lawyerSignatureSecond').setValue((await this.blobToBase64(res)) || '');
        }
        resolve(true);
       } else {
        resolve(false);
       }
      },
      error => {
       reject(error);
       this.toastr.error('Signature not found', 'Error');
      },
     );
   }
  });
 }

 async getLawyerSignature(formName, id, formControlName) {
  return new Promise((resolve, reject) => {
   const params = { userId: id };
   this.commonService
    .getFile(params, `${this.base_url}${Endpoints.getSignature}`)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
     async (res: any) => {
      if (res) {
       const base64Data = await this.blobToBase64(res);

       if (formName) {
        formName.get(formControlName).setValue(base64Data || '');
       } else {
        formControlName.setValue(base64Data || '');
       }
       resolve(true);
      } else {
       resolve(false);
      }
     },
     error => {
      reject(error);
      this.toastr.error('Signature not found', 'Error');
     },
    );
  });
 }

 getClerkSignature(formName, id, message: string) {
  const params = { userId: id };
  this.commonService
   .getFile(params, `${this.base_url}${Endpoints.getSignature}`)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    async (res: any) => {
     if (res) {
      this.toastr.success('Signature added successfully', 'Success');
      formName.get('clerkSignature').setValue((await this.blobToBase64(res)) || '');
      formName?.get('para11')?.get('details')?.setValue(message);
     } else {
      formName.get('clerkSignature').setValue('');
     }
    },
    error => {
     formName.get('clerkSignature').setValue('');
     this.removeSignature(formName);
     this.toastr.error('Signature not found', 'Error');
    },
   );
 }

 removeSignature(formName) {
  formName.get('clerkSignature').setValue('');
 }

 getParamsUrl() {
  const url = window.location.href;
  const urlSplit = url.split('?');
  if (urlSplit.length > 1) {
   const params = urlSplit[1].split('&');
   const paramsObj = {};
   params.forEach(param => {
    const key = param.split('=')[0];
    const value = param.split('=')[1];
    paramsObj[key] = value;
   });

   return paramsObj;
  }
 }

 blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
   const reader = new FileReader();
   reader.onloadend = () => resolve(reader.result);
   reader.readAsDataURL(blob);
  });
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
