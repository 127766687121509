import { INavData } from '@coreui/angular';

export const matterNavItems: INavData[] = [
 {
  name: 'Home',
  url: '/home',
  icon: 'icon-home',
 },
 {
  class: 'border-bottom',
  divider: true,
 },
 {
  divider: true,
 },
 {
  name: 'Accounts',
  url: '/accounts',
  icon: 'icon-notebook',
 },
 {
  name: 'Calendar',
  url: '/calendar',
  icon: 'icon-calendar',
 },
 {
  name: 'Matters',
  url: '/matters',
  icon: 'icon-drawer',
 },
 {
  name: '',
  icon: '',
  url: 'clientname',
  class: 'child-side-bar',
 },
 {
  name: 'Memo(s)',
  icon: 'icon-layers',
  class: 'memo-nav child-side-bar',
  url: '/matters/memo',
 },
 {
  name: 'Accounting',
  icon: 'icon-note',
  class: 'accounting-nav child-side-bar',
  url: '/matters/accounting',
 },
 {
  name: 'Approvals',
  url: '/matters/approvals',
  icon: 'icon-bell',
  class: 'child-side-bar',
 },
 {
  name: 'Brief',
  url: '/matters/brief-dockets',
  icon: 'icon-layers',
  class: 'child-side-bar',
 },
 {
  name: 'Compliance',
  url: '/matters/compliance',
  icon: 'icon-speech',
  class: 'child-side-bar',
 },
 {
  name: 'Case Progress/Tasks',
  url: '/matters/case-progress',
  icon: 'icon-docs',
  class: 'child-side-bar',
 },
 {
  name: 'Data',
  url: '/matters/data',
  icon: 'icon-docs',
  class: 'child-side-bar',
 },
 {
  name: 'Dockets',
  url: '/matters/dockets',
  icon: 'icon-layers',
  class: 'child-side-bar',
 },
 {
  name: 'Documents',
  url: '/matters/documents',
  icon: 'icon-doc',
  class: 'child-side-bar',
  badge: {
   text: '',
   variant: 'success',
   class: 'custom-badge-class-document',
  },
 },
 {
  name: 'Forms',
  url: '/matters/forms',
  icon: 'icon-docs',
  class: 'child-side-bar',
 },
 {
  name: 'Messages',
  url: '/matters/chat',
  icon: 'fa fa-commenting-o',
  class: 'child-side-bar ',
  badge: {
   text: '',
   variant: 'success',
   class: 'custom-badge-class-message',
  },
 },
 {
  name: 'Precedents',
  url: '/matters/precedents',
  icon: 'icon-graph',
  class: 'child-side-bar',
 },
 {
  name: 'References',
  url: '/matters/references',
  icon: 'icon-notebook',
  class: 'child-side-bar',
 },

 {
  name: 'Retainer',
  url: '/matters/retainer',
  icon: 'icon-graph',
  class: 'child-side-bar',
 },
 {
  class: 'border-bottom',
  divider: true,
 },
 {
  divider: true,
 },
 {
  name: 'Settings',
  icon: 'icon-settings',
  class: 'child-settings',
  url: 'javascript:void(0)',
  children: [
   {
    name: 'DEACTIVATE CLIENT',
    url: '',
    linkProps: { queryParams: { action: 'deactivate' } },
   },
   {
    name: 'RESEND PASSWORD',
    url: '',
    class: 'warning',
    linkProps: { queryParams: { action: 'password' } },
   },
   {
    name: 'MOVE TO ARCHIVE',
    url: '',
    linkProps: { queryParams: { action: 'archive' } },
   },
  ],
 },
];
