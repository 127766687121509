import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';
import {
  ApiService,
  AuthService,
  CommonService,
  DataService
} from '../../../services';

@Component({
  selector: 'app-precedent',
  templateUrl: './precedent.component.html',
  styleUrls: ['./precedent.component.scss']
})
export class PrecedentComponent implements OnInit, OnDestroy {
  @ViewChild('addCategoryModal') public addCategoryModal: ModalDirective;
  @ViewChild('addPrecedentModal') public addPrecedentModal: ModalDirective;
  @ViewChild('deleteCategoryModal') deleteCategoryModal: ModalDirective;

  addCategoryForm: FormGroup;
  deleteCategoryForm: FormGroup;
  addPrecedent: FormGroup;
  isPrecedentAdded: boolean = false;
  accessLevel;
  userDetails;
  matterDetails;
  matterTypeSelected = 'Family';
  precedentRecords;
  precedentCat = [];
  precedentValue;
  matterTypes = ['Family', 'Immigration', 'Criminal'];
  catId: any;
  editPrecedent: boolean = false;
  editCat: string;
  isExist: boolean;
  selectedSubCategory: any;
  recipient: any;
  destroy$ = new Subject<boolean>();
  allowUpdate = false;

  constructor(
    private dataService: DataService,
    private commonService: CommonService,
    private api: ApiService,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.commonService.allowUpdate
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.allowUpdate = res;
      });
  }

  Endpoints = Endpoints;
  addCategory: boolean = false;
  selectCats: any;
  selectDeleteCats: any;

  ngOnInit(): void {
    this.commonService.callAddCat.subscribe(res => {
      if (res) {
        this.showModal(true);
      }
    });

    this.initializeForm();
    this.accessLevel = this.authService.getUserAccessLevel();
    const nav = this.router.url.split(/[/ ]+/).pop();

    this.isExist = this.router.url.includes('admin');
    const urlData = this.router.url;
    const matterRoute = urlData.includes('/matters') ? true : false;
    this.dataService.setMatterDetailActive(true);
    this.dataService.setAddMatterButtonActive(false);
    this.commonService.showSidebarCalendar(false);
    if (matterRoute) {
      this.dataService.setUserDetailActive(false);
      this.commonService.showSidebarCalendar(true);
    }

    if (this.dataService.matterData.getValue() !== null) {
      this.userDetails = this.dataService.matterData.getValue().userDetails;
      this.matterDetails = this.dataService.matterData.getValue().matterDetails;
      this.dataService.setMatterDetails(this.matterDetails);
    } else {
      try {
        const matter = JSON.parse(localStorage.getItem('matterDetails'));
        this.matterDetails = matter;
        this.userDetails = matter.user;
        this.dataService.setMatterDetails(this.matterDetails);
      } catch (err) {}
    }

    if (matterRoute) {
      this.matterTypeSelected = this.matterDetails?.matterType;
    }

    this.getPrecedentCategory();
    this.commonService.precedentTabChange.next('Family');
  }

  initializeForm() {
    this.addPrecedent = this.formBuilder.group({
      matterType: ['Family'],
      precedentName: ['', Validators.required],
      category: ['', Validators.required],
      precedentDesc: ['', Validators.required]
    });
    this.addCategoryForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.deleteCategoryForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get getControls() {
    return this.addPrecedent.controls;
  }

  getPrecedentCategory() {
    this.api
      .getParams(Endpoints.precedentCat, {
        matterType: this.matterTypeSelected
      })

      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          if (response?.length > 0) {
            response.sort((a, b) =>
              a.name === 'Correspondence'
                ? -1
                : b.name === 'Correspondence'
                  ? 1
                  : 0
            );

            this.precedentCat = response;

            this.precedentCat[0].active = true;
          }
        },
        err => {
          console.error('the error', err);
        }
      );
    setTimeout(() => {
      document
        .querySelectorAll('.bs-modal-backdrop, .modal-backdrop')
        .forEach(backdrop => {
          if (backdrop) {
            backdrop.remove();
          }
        });
    }, 500);
  }

  selectCat(ev: any) {
    this.addCategoryForm.controls['name'].setValue(ev.target.value);
    this.selectCats = this.precedentCat.filter(
      cat => cat.name === ev.target.value
    )[0];
  }

  onSelectCategory(ev: any) {
    this.selectedSubCategory = ev.heading;

    this.addPrecedent.controls['category'].setValue(ev.target.value);
    this.catId = this.precedentCat.filter(
      cat => cat.name === ev.target.value
    )[0];
  }

  onCancelCategory() {
    this.addCategoryForm.reset();
    this.addCategoryModal.hide();

    setTimeout(() => {
      document
        .querySelectorAll('.bs-modal-backdrop, .modal-backdrop')
        .forEach(backdrop => {
          if (backdrop) {
            backdrop.remove();
          }
        });
    }, 500);
  }

  onCancelPrecedent() {
    this.addPrecedent.reset();
    this.addPrecedentModal.hide();
    this.addPrecedent.controls['matterType'].setValue(this.matterTypeSelected);
  }

  onPrecedentUpdate(ev: string) {
    if (ev === 'true') {
      setTimeout(() => {
        this.getPrecedentCategory();
      }, 300);
    }
  }

  addPrecedentInfo() {
    if (this.addPrecedent.invalid) {
      return;
    }

    const length = this.commonService.precedentArray?.filter(
      (item: any) =>
        item.category?._id === this.catId?._id &&
        this.addPrecedent.value.matterType === item.matterType
    ).length;
    this.editPrecedent = true;
    const precedentData = {
      subTabName: this.selectedSubCategory,
      category: this.catId._id,
      title: this.addPrecedent.value.precedentName,
      precedent: this.addPrecedent.value.precedentDesc,
      matterType: this.addPrecedent.value.matterType,
      order: length + 1
    };

    this.api
      .post(`${Endpoints.precedent}`, precedentData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.addPrecedent.reset();

          this.addPrecedentModal.hide();
          this.addPrecedent.controls['matterType'].setValue(
            this.matterTypeSelected
          );
          this.dataService.setPrecedent(true);
          this.toastr.success(
            this.editPrecedent === true
              ? MESSAGE.TOASTR.MESSAGE_UPDATEPRECEDENT
              : MESSAGE.TOASTR.MESSAGE_ADDPRECEDENT,
            MESSAGE.INFO_MESSAGES.SUCCESS
          );
          this.editPrecedent = false;
        },
        err => {
          this.editPrecedent = false;
        }
      );
  }
  public isDuplicateCategory(name: string): boolean {
    if (!name?.trim()) return false;

    const newTabName = name.trim().toLowerCase();
    return this.precedentCat.some(
      category => category.name.trim().toLowerCase() === newTabName
    );
  }

  addNewCategory() {
    if (this.addCategoryForm.invalid) {
      return;
    }
    const newTabName = this.addCategoryForm.value.name.trim().toLowerCase();
    if (this.isDuplicateCategory(newTabName)) {
      this.toastr.info(MESSAGE.INFO_MESSAGES.DUPLICATE_CATEGORY);
      return;
    }
    const params = {
      // categoryName: this.addCategoryForm.value,
      ...this.addCategoryForm.value,
      matterType: this.matterTypeSelected
    };
    //need to get selected tabs
    const apiEnds = this.addCategory
      ? this.api.post(`${Endpoints.precedentCat}`, params)
      : this.api.put(
          `${Endpoints.precedentCat}/${this.selectCats._id}`,
          params
        );
    apiEnds.pipe(takeUntil(this.destroy$)).subscribe(
      (response: any) => {
        this.addCategoryForm.reset();
        this.addCategoryModal.hide();

        this.toastr.success(
          this.addCategory === true
            ? MESSAGE.TOASTR.MESSAGE_ADDPRECEDENTCAT
            : MESSAGE.TOASTR.MESSAGE_UPDATEPRECEDENTCAT,
          MESSAGE.INFO_MESSAGES.SUCCESS
        );
        setTimeout(() => {
          this.getPrecedentCategory();
        }, 600);
      },
      err => {}
    );
  }

  //Function used to pen the add sub tabs
  showModal(isAdd: boolean) {
    this.addCategory = isAdd;
    this.addCategoryModal.show();
  }

  savePrecedent() {
    this.addPrecedentModal.show();
  }

  onTabChange(ev: any) {
    this.matterTypeSelected = ev.heading;
    this.addPrecedent.controls['matterType'].setValue(ev.heading);
    this.commonService.precedentTabChange.next(ev.heading);
    this.allowUpdate = false;
    this.commonService.allowUpdate.next(false);
    this.getPrecedentCategory();
  }

  onSubTabChange(ev: any) {
    this.selectedSubCategory = ev.heading;

    this.allowUpdate = false;
    this.commonService.allowUpdate.next(false);
  }

  showDeleteCategoryModal() {
    this.deleteCategoryModal.show();
    const storedCategory =
      localStorage.getItem('selectedPrecedentCategory') || '';
    if (
      storedCategory &&
      this.precedentCat.some(cat => cat.name === storedCategory)
    ) {
      this.selectDeleteCats = this.precedentCat.find(
        cat => cat.name === storedCategory
      );
      this.selectDeleteCats.name !== 'Correspondence'
        ? this.deleteCategoryForm.patchValue({ name: storedCategory })
        : '';
    }
  }

  selectDeleteCat(ev: any) {
    this.selectDeleteCats = this.precedentCat.filter(
      cat => cat.name === ev.target.value
    )[0];
  }

  deleteCategory() {
    try {
      if (this.deleteCategoryForm.invalid) {
        return;
      }

      const apiEnds = this.api.delete(
        `${Endpoints.precedentCat}/${this.selectDeleteCats._id}`
      );
      apiEnds.pipe(takeUntil(this.destroy$)).subscribe(
        (response: any) => {
          this.toastr.success(
            MESSAGE.TOASTR.MESSAGE_DELETEPRECEDENTCAT,
            MESSAGE.INFO_MESSAGES.SUCCESS
          );
          setTimeout(() => {
            this.getPrecedentCategory();
          }, 600);
        },
        err => {
          console.log('🚀 ~ PrecedentComponent ~ deleteCategory ~ err:', err);
        }
      );
    } catch (error) {
      console.log('** ->  ~ deleteCategory ~ error:', error);
    } finally {
      this.deleteCategoryModal.hide();
      this.deleteCategoryForm.reset();
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
